<template>
  <div
    id="lp-footer"
    :class="[
      'lp-sec__footer',
      {
        'bottom-space': !isAuthenticated && $route.name === 'Courses Detail',
        'lp-footer__awards': isAchievementsDetail
      }
    ]"
  >
    <div class="container">
      <div class="row footer-top">
        <div class="col-12 col-lg-8 footer__top-left">
          <div class="row">
            <div class="col-12 col-md-3 quick-links">
              <img
                :src="
                  require(`@/assets/images/logo/whoa-logo-${
                    isAchievementsDetail ? 'white' : 'black'
                  }-${logoLanguage}.svg`)
                "
                height="48"
                class="footer-logo"
                @click="navigateTo('/')"
              />
            </div>
            <div class="col-12 col-md-3 quick-links">
              <div class="footer-heading" @click="toggleMenu('item1')">
                <span>{{ $t("course.actions.resources") }}</span>
                <span class="arrow" v-if="isMobile">
                  <img
                    :src="arrowDownIcon"
                    alt="arrow"
                    :style="showList.item1 ? 'transform: rotate(180deg);' : ''"
                  />
                </span>
              </div>
              <ul class="footer-list" v-if="showList.item1">
                <li>
                  <a href="/discovery" class="list-link">
                    {{ $t("general.menu.discovery") }}
                  </a>
                </li>
                <li>
                  <a href="/coursewares" class="list-link">
                    {{ $t("course.breadcrumb.course") }}
                  </a>
                </li>
                <li>
                  <a href="#" class="list-link">
                    {{ $t("landing_page.footer.get_the_app") }}
                  </a>
                </li>
                <li>
                  <a :href="getPartnerLink" target="_blank" class="list-link">
                    {{ $t("landing_page.footer.become_partner") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-3 quick-links">
              <div class="footer-heading" @click="toggleMenu('item2')">
                <span>{{ $t("landing_page.footer.partners") }}</span>
                <span class="arrow" v-if="isMobile">
                  <img
                    :src="arrowDownIcon"
                    alt="arrow"
                    :style="showList.item2 ? 'transform: rotate(180deg);' : ''"
                  />
                </span>
              </div>
              <ul class="footer-list" v-if="showList.item2">
                <li>
                  <a
                    href="#"
                    @click.prevent="navigateToSection"
                    class="list-link"
                  >
                    {{ $t("landing_page.footer.trusted_partners") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-3 quick-links">
              <div class="footer-heading" @click="toggleMenu('item3')">
                <span>{{ $t("landing_page.footer.we_care") }}</span>
                <span class="arrow" v-if="isMobile">
                  <img
                    :src="arrowDownIcon"
                    alt="arrow"
                    :style="showList.item3 ? 'transform: rotate(180deg);' : ''"
                  />
                </span>
              </div>
              <ul class="footer-list" v-if="showList.item3">
                <li>
                  <a
                    href="javascript:;"
                    @click="openFeedbackForm"
                    class="list-link"
                  >
                    {{ $t("dashboard.feedback.button") }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://whoacademy.atlassian.net/wiki/spaces/WHOAFAQ/"
                    target="_blank"
                    class="list-link"
                  >
                    {{ $t("landing_page.footer.faqs") }}
                  </a>
                </li>
                <li>
                  <a href="/accessibility-statement" class="list-link">
                    {{ $t("landing_page.footer.accessibility_statement") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 footer__top-right">
          <div class="footer-heading">
            {{ $t("landing_page.footer.about.title") }}
          </div>
          <div class="about-content">
            {{ $t("landing_page.footer.about.content") }}
          </div>
          <div class="social__links--container">
            <a
              href="https://www.facebook.com/WHO/"
              class="social-link"
              target="_blank"
            >
              <img
                :src="
                  require(`@/assets/images/social-media/facebook${
                    isAchievementsDetail ? '-white' : ''
                  }.svg`)
                "
                alt="facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/who/"
              class="social-link"
              target="_blank"
            >
              <img
                :src="
                  require(`@/assets/images/social-media/instagram${
                    isAchievementsDetail ? '-white' : ''
                  }.svg`)
                "
                alt="instagram"
              />
            </a>
            <a
              href="https://twitter.com/WHO"
              class="social-link"
              target="_blank"
            >
              <img
                :src="
                  require(`@/assets/images/social-media/twitter${
                    isAchievementsDetail ? '-white' : ''
                  }.svg`)
                "
                alt="twitter"
              />
            </a>
            <a
              href="https://www.youtube.com/who"
              class="social-link"
              target="_blank"
            >
              <img
                :src="
                  require(`@/assets/images/social-media/youtube${
                    isAchievementsDetail ? '-white' : ''
                  }.svg`)
                "
                alt="youtube"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="row">
          <div class="col-12 col-md-6">
            <ul class="footer__links--left">
              <li class="list-text">
                {{
                  $t("landing_page.footer.copyright", {
                    s: getYear()
                  })
                }}
              </li>
              <li>
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  class="list-link2"
                >
                  {{ $t("landing_page.footer.terms_conditions") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6">
            <ul class="footer__links--right">
              <li>
                <a
                  href="https://www.who.int/about/who-academy"
                  class="list-link2"
                  target="_blank"
                >
                  {{ $t("landing_page.footer.about_us") }}
                </a>
              </li>
              <li>
                <a
                  href="https://www.who.int/about/who-academy/get-involved"
                  class="list-link2"
                  target="_blank"
                >
                  {{ $t("landing_page.footer.get_involved") }}
                </a>
              </li>
              <li>
                <a
                  href="mailto:whoasupport@who.int?subject=Form%20enquiry%20from%20WHO%20Academy%20landing%20page&body=Hello!"
                  class="list-link2"
                  target="blank"
                >
                  {{ $t("landing_page.footer.contact_us") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// mixins
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import getCurrentYear from "@/core/mixins/getCurrentYear";
import getLogo from "@/core/mixins/getLogo";

export default {
  mixins: [openFeedbackForm, getCurrentYear, getLogo],
  computed: {
    ...mapGetters(["allConfig"]),
    arrowIcon() {
      return this.isFooterVisible
        ? require("@/assets/images/vle/chevron-up.svg")
        : require("@/assets/images/vle/chevron-down.svg");
    },
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    getPartnerLink() {
      return this.allConfig.environment === "demo"
        ? "https://lms-staging.lxp.academy.who.int"
        : this.allConfig.environment === "testing"
        ? "https://lms-uat.lxp.academy.who.int"
        : "https://cms-dev.apps.develop.lxp.academy.who.int";
    }
  },
  data() {
    return {
      isMobile: false,
      isFooterVisible: true,
      arrowDownIcon: require("@/assets/images/arrow.svg"),
      showList: {
        item1: true,
        item2: false,
        item3: false
      }
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    if (!this.isMobile) {
      this.showList.item1 = true;
      this.showList.item2 = true;
      this.showList.item3 = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    navigateTo(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    navigateToSection() {
      this.$router
        .push({ name: "discovery" })
        .then(() => {
          this.scrollToSection();
        })
        .catch(err => {
          if (err.name === "NavigationDuplicated") {
            this.scrollToSection();
          }
        });
    },
    scrollToSection() {
      this.$nextTick(() => {
        const section = document.getElementById("partners-section");
        if (section) {
          const offset = document.getElementById("lp-header")?.offsetHeight;
          const sectionTop =
            section.getBoundingClientRect().top + window.scrollY - offset;
          window.scrollTo({ top: sectionTop });
        }
      });
    },
    toggleFooter() {
      this.isFooterVisible = !this.isFooterVisible;
    },
    toggleMenu(item) {
      if (this.isMobile) {
        this.showList[item] = !this.showList[item];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.lp-sec__footer {
  background-color: $brand-primary-50;
  padding: 32px 17px 48px 17px;
  text-align: left;
  font-family: $font-family;
  box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.25);
  position: relative;
  &.lp-footer__awards {
    background: $brand-primary;
    .footer-top {
      .footer-heading,
      .footer-list .list-link,
      .about-content {
        color: $brand-neutral-0;
      }
    }
    .footer-bottom {
      .footer__links--left,
      .footer__links--right {
        .list-text,
        .list-link2 {
          color: $brand-neutral-0;
        }
      }
    }
  }
  .footer-top {
    .footer-logo {
      cursor: pointer;
    }
    .footer-heading {
      @include button-label;
      @include flex-center;
      color: $brand-neutral-900;
      margin-bottom: 8px;
      text-transform: unset;
      letter-spacing: 0.15px;
    }
    .footer-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
      .list-link {
        @include body-regular($brand-neutral-700);
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .about-content {
      @include body-medium;
      color: $brand-neutral-700;
    }
    .social__links--container {
      margin-top: 8px;
      @include flex-center;
      .social-link {
        width: 40px;
        min-width: 40px;
        height: 40px;
        @include flex-horizontal-center;
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid $brand-neutral-200;
    .footer__links--left,
    .footer__links--right {
      @include flex-center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
      .list-text {
        @include label-large($brand-neutral-700, 500);
      }
      .list-link2 {
        @include body-medium;
        color: $brand-neutral-700;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .footer__links--right {
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 1280px) {
  .lp-sec__footer .container {
    max-width: 1280px;
  }
}

@media screen and (max-width: 1199px) {
  .lp-sec__footer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .lp-sec__footer {
    &.bottom-space {
      padding-bottom: 171px;
    }
  }
  .footer__top-right {
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .lp-sec__footer {
    padding-bottom: 16px;
    &.bottom-space {
      padding-bottom: 120px;
    }
    .footer__top-left {
      .quick-links {
        &:not(:first-child) {
          padding-top: 19px;
          .footer-list {
            padding-bottom: 11px;
            border-bottom: 1px solid $brand-neutral-200;
          }
        }
        .footer-heading {
          .arrow {
            margin-left: 8px;
          }
        }
      }
    }
    .footer-bottom {
      .footer__links--left,
      .footer__links--right {
        width: 100%;
        justify-content: center;
      }
      .footer__links--right {
        margin-top: 16px;
      }
    }
  }
}

// arabic alignment
[dir="rtl"] {
  .lp-sec__footer {
    text-align: right;
  }
}
</style>
